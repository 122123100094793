import React from "react";
import ImageElement from "@/helper/static-image";

type LogosType = {
  title: string;
};
const Logos: React.FC<LogosType> = ({
  title
}) => {
  return (
    <section
      className="m-logos__wrapper dark"
      data-scroll-section
      id="logos-marker"
    >
      <div
        className="c-logos__container container"
        data-scroll
        data-scroll-offset="70%"
        data-scroll-target="#logos-marker"
      >
        <div
          className="logos-header"
        >
          <h2 className="heading-logos">{title}</h2>
        </div>

        <div className="c-logos-items"
             data-scroll
             data-scroll-speed="1.1"
             data-scroll-target="#logos-marker">
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo0"
              alt="Planning & Environment"
            />
          </div>
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo1"
              alt="Public Service Commision"
            />
          </div>
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo2"
              alt="Education"
            />
          </div>
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo3"
              alt="Education Standards Authority"
            />
          </div>
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo4"
              alt="Health"
            />
          </div>
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo5"
              alt="Transport NSW"
            />
          </div>
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo6"
              alt="Office of Sport"
            />
          </div>
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo7"
              alt="Forestry Corporation"
            />
          </div>
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo8"
              alt="Sydney Water"
            />
          </div>
          <div className={"c-logos-item"}>
            <ImageElement
              asset="logo9"
              alt="Tafe NSW"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Logos;
