import React from "react";
import Obfuscate from "react-obfuscate";

const ContactDetails: React.FC = () => (
  <div className="e-contact-details">
    <Obfuscate email="hellosydney@thebeinggroup.com" />
    <Obfuscate tel="+61 2 9816 4068" />
    <Obfuscate href="https://thebeinggroup.com">
      thebeinggroup.com
    </Obfuscate>
  </div>
);

export default ContactDetails;
