import React from "react";

const ShowcaseIntro: React.FC = () => (
  <div className="c-showcase-intro">
    <div className="c-showcase-intro__inner">
      <div className="c-showcase-intro__three">
        <span
          className="c-showcase-intro__text"
        >
          Showcase Showcase Showcase Showcase Showcase Showcase Showcase
          Showcase Showcase Showcase
        </span>
      </div>
    </div>
  </div>
);

export default ShowcaseIntro;
