import React from "react";
import Layout from "@/layout";
import SeoComponent from "@/modules/seo";
import Hero from "@/modules/hero";
import Intro from "@/modules/intro";
import Video from "@/modules/video";
import Services from "@/modules/services";
import Logos from "@/modules/logos";
import Showcase from "@/modules/showcase";

type IndexType = {
  pageContext: any;
};

const Index: React.FC<IndexType> = ({ pageContext }) => {
  const {
    title,
    description,
    keywords,
    accentColour,
    hero,
    intro,
    video,
    services,
    showcase,
    logos,
    footer,
  } = pageContext;

  return (
<React.StrictMode>
  <Layout
    colour={accentColour}
    footerL1={footer.lineOne}
    footerL2={footer.lineTwo}
    data-scroll-section
  >
      <SeoComponent
        title={title}
        description={description}
        keywords={keywords}
      />
      <Hero
        lineOne={hero.lineOne}
        lineTwo={hero.lineTwo}
        cta={hero.cta}
      />
      <Intro
        col1Header={intro.col1Header}
        col1Paragraph1={intro.col1Paragraph1}
        col1Paragraph2={intro.col1Paragraph2}
        col2Header={intro.col2Header}
        col2Paragraph1={intro.col2Paragraph1}
        col2Paragraph2={intro.col2Paragraph2}
      />
      <Video
        videoItems={video}
        title={video.title}
        link={video.link}
        videoUrl={video.videoUrl}
      />
      <Services
        title={services.title}
        service1={services.items[0]}
        service2={services.items[1]}
        service3={services.items[2]}
        service4={services.items[3]}
        service5={services.items[4]}
        service6={services.items[5]}
      />
      <Showcase showcaseItems={showcase.items} />
      <Logos title={logos.title} />
    </Layout>
</React.StrictMode>

);
};

export default Index;
