import React, { useEffect, useState } from "react";
import { ImageType } from "@/types";
import RightArrow from "@/img/right-arrow.svg";
import Arrow from "@/img/arrow.svg";
import ImageElement from "@/helper/static-image";
import {StaticImage} from "gatsby-plugin-image";

export type ShowcaseItemType = {
  videoTitle: string;
  title: string;
  description: string;
  outcome: string;
  link: string;
  image?: ImageType;
  video?: string;
  index: number;
  imageLength: number;
};


const ShowcaseItem: React.FC<ShowcaseItemType> = ({
  videoTitle,
  title,
  description,
  outcome,
  image,
  link,
  imageLength
}) => {
  const [index, setIndex] = useState(0);

  const handleNext = () => {
    const nextIndex = index >= imageLength - 1 ? 0 : index + 1;
    setIndex(nextIndex);
  };

  const divElements = Array.from({ length: imageLength }, (_, indeks) => (
    <div
      className={`slide c-showcase-item__media ${
        indeks === index ? "active" : ""
      }
      ${indeks === index - 1 || (index === 0 && indeks === imageLength - 1)
        ? "prev"
        : ""}
      ${indeks === index + 1 || (index === imageLength - 1 && indeks === 0)
        ? "next"
        : ""}
      `}
      key={indeks}
    >
      <ImageElement asset={image.asset + indeks} alt={image.alt} />
    </div>
  ));



  return (
    <div
      className="c-showcase-item__wrapper"
      id={`showcase-item-marker-${index}`}
    >
      <div className="c-showcase-item container">
        <div className="c-showcase-item__text">
          <span  className="m-hero__heading-inline line-3 is-inview pb-1">
            {videoTitle}
          </span>
          <a
            className="c-showcase-item__link"
            href={link}
            data-scroll
            data-scroll-repeat
            data-scroll-offset="75%"
            data-scroll-target={`#showcase-item-marker-${index}`}
            target="_blank"
            rel="noopener noreferrer"
          >

            <h3 className="c-showcase-item__heading heading-showcase">
              {title}
            </h3>
            {/*<RightArrow className="c-showcase-item__arrow" />*/}
          </a>
          <p className="c-showcase-item__description body-small">
            {description}
          </p>
          <p className="body-small pad-top">{outcome}</p>
        </div>
        <div
          className="c-showcase-item__media-wrapper slideshow"
          // href={link}
          // target="_blank"
          // rel="noopener noreferrer"
          aria-label={title}
        >
        {divElements}
          <Arrow className="c-showcase-item__arrow next-button" />
          <Arrow className={"next-button"} onClick={handleNext}>Next</Arrow>
        </div>
      </div>
    </div>
  );
};

export default ShowcaseItem;
