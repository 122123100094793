import React from "react";

type IntroType = {
  col1Header: string;
  col1Paragraph1: string;
  col1Paragraph2: string;
  col2Header: string;
  col2Paragraph1: string;
  col2Paragraph2: string;
};
const Intro: React.FC<IntroType> = ({
  col1Header, col1Paragraph1, col1Paragraph2, col2Header, col2Paragraph1, col2Paragraph2
}) => {
  return (
    <section
      className="c-intro__wrapper light"
      data-scroll-section
      id="intro-marker"
    >
      <div
        className="c-intro container"
        data-scroll
        data-scroll-offset="40%"
        data-scroll-target="#intro-marker"
      >
        <div
          className="e-intro col-1"
          data-scroll
          data-scroll-speed="1.1"
          data-scroll-target="#intro-marker"
        >
          <h2 className="c-intro-list__heading heading-intro">{col1Header}</h2>
          <p>{col1Paragraph1}</p>
          <p className="pad-top">{col1Paragraph2}</p>
        </div>

        <div
          className="e-intro col-2"
          data-scroll
          data-scroll-speed="1.1"
          data-scroll-target="#intro-marker"
        >
          <h2 className="c-intro-list__heading heading-intro">{col2Header}</h2>
          <p>{col2Paragraph1}</p>
          <p className="pad-top">{col2Paragraph2}</p>
        </div>
      </div>
    </section>
  );
};

export default Intro;
