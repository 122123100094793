import Jumbled from "@/elements/jumbled";
import React from "react";
import RightArrow from "@/img/right-arrow.svg";
import ImageElement from "@/helper/static-image";

type HeroType = {
  lineOne: string;
  lineTwo: string;
  cta?: string;
};

const Hero: React.FC<HeroType> = ({ lineOne, lineTwo, cta }) => {
  return (
    <section
      className="m-hero__wrapper dark"
      data-scroll-section
      data-scroll
      id="hero-marker"
    >
      <div className="m-hero container">
        <div className="m-hero__text">
          <h2 className="m-hero__heading heading-large">
            <span>
              <span
                className="m-hero__heading-inline line-one"
                data-scroll
                data-scroll-speed="5"
                data-scroll-target="#hero-marker"
              >
                {lineOne}
              </span>
            </span>
            <Jumbled word={lineTwo} speed={4} target="#hero-marker" />
          </h2>
          <div className="m-hero__link-wrapper ">
            <a
              className="m-hero__link underline -hover-underline"
              href="#footer-marker"
              data-scroll
              data-scroll-speed="4"
              data-scroll-target="#hero-marker"
              data-scroll-to="#work-form-marker"
            >
              {cta}
              <RightArrow
              className="m-hero__link-icon"
            />
            </a>


          </div>
        </div>
        <div className="m-hero__media-wrapper">
          <div
            className="m-hero__media"
            data-scroll
            data-scroll-speed="-3"
            data-scroll-target="#hero-marker"
          >
            <img src="../image/Spark_reveal_500x500.gif"/>
          </div>
        </div>
      </div>

    </section>
  );
};

export default Hero;
