import React, { useState } from "react"
import { StaticImage, GatsbyImageProps } from "gatsby-plugin-image";
import { ImageType } from "@/types";

const ImageElement: React.FC<ImageType> = ({ asset, alt  }) => {
  return (
    <>
    {asset === "placeholder" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/placeholder.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
      />
    )}
    {asset === "checkin" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/Checkin-close-PS.png"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
      />
    )}
      {asset === "spark" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/Spark_reveal_500x500.gif"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {/*-------------logos-------------------*/}
      {asset === "logo0" && (
        <StaticImage
          src="../static/logos/NSW-Logos0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {asset === "logo1" && (
        <StaticImage
          src="../static/logos/NSW-Logos1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {asset === "logo2" && (
        <StaticImage
          src="../static/logos/NSW-Logos2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {asset === "logo3" && (
        <StaticImage
          src="../static/logos/NSW-Logos3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {asset === "logo4" && (
        <StaticImage
          src="../static/logos/NSW-Logos4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {asset === "logo5" && (
        <StaticImage
          src="../static/logos/NSW-Logos5.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {asset === "logo6" && (
        <StaticImage
          src="../static/logos/NSW-Logos6.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {asset === "logo7" && (
        <StaticImage
          src="../static/logos/NSW-Logos7.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {asset === "logo8" && (
        <StaticImage
          src="../static/logos/NSW-Logos8.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {asset === "logo9" && (
        <StaticImage
          src="../static/logos/NSW-Logos9.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
        />
      )}
      {/*-------------logos-------------------*/}

      {/*-------------ECP-------------------*/}
      {asset === "ECP4" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/ECP/ECP-5.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        height={300}
        placeholder="blurred"
      />
    )}{asset === "ECP3" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/ECP/ECP-4.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        height={300}
        placeholder="blurred"
      />
    )}{asset === "ECP2" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/ECP/ECP-3.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        height={300}
        placeholder="blurred"
      />
    )}{asset === "ECP1" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/ECP/ECP-2.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        height={300}
        placeholder="blurred"
      />
    )}
      {asset === "ECP0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/ECP/ECP-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-------------ECP-------------------*/}

      {/*-------------------WSA-------------------*/}
      {asset === "WSA5" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-6.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-5.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-4.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-3.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-2.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-------------------WSA-------------------*/}

      {/*-------------------WSA-------------------*/}
      {asset === "WSA5" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-6.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-5.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-4.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-3.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-2.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "WSA0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/WSA/WSA-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-------------------WSA-------------------*/}

      {/*-------------------PAW-------------------*/}
      {asset === "PAW4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PAW/PAW-5.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PAW3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PAW/PAW-4.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PAW2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PAW/PAW-3.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PAW1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PAW/PAW-2.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PAW0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PAW/PAW-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-------------------PAW-------------------*/}

      {/*-------------------PSC-------------------*/}
      {asset === "PSC10" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-11.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC9" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-10.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC8" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-9.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC7" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-8.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC6" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-7.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC5" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-6.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-5.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-4.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-3.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PSC/PSC-2.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PSC0" && (
        <StaticImage
        className="c-showcase-item__media"
        src="../static/PSC/PSC-1.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        height={300}
        placeholder="blurred"
        />
        )}
      {/*-------------------PSC-------------------*/}

      {/*-------------------AK-------------------*/}
      {asset === "AK9" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-10.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK8" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-9.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK7" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-8.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK6" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-7.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK5" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-6.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-5.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-4.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-3.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-2.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-1.jpg"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-------------------AK-------------------*/}
  </>
  )
};

export default ImageElement;
