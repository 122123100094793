import React from "react";

export type service1 = {
  title: string;
  description: string;
}

export type service2 = {
  title: string;
  description: string;
}

export type service3 = {
  title: string;
  description: string;
}

export type service4 = {
  title: string;
  description: string;
}

export type service5 = {
  title: string;
  description: string;
}

export type service6 = {
  title: string;
  description: string;
}

type ServicesType = {
  title: string;
  service1: service1[];
  service2: service2[];
  service3: service3[];
  service4: service4[];
  service5: service5[];
  service6: service6[];
};
const Services: React.FC<ServicesType> = ({
  title, service1, service2, service3, service4, service5, service6,
}) => {
  return (
    <section
      className="c-services__wrapper light"
      data-scroll-section
      id="services-marker"
    >
      <div
        className="c-services container"
        data-scroll
        data-scroll-offset="40%"
        data-scroll-target="#services-marker"
      >
        <div
          className="services-services-header"
          data-scroll
          data-scroll-speed="1.1"
          data-scroll-target="#services-marker"
        >
          <h2 className="c-services-header__1 heading-service">{title}</h2>
        </div>

        <div
            data-scroll
            data-scroll-speed="1.1"
            data-scroll-target="#services-marker"
        >
          <div className="c-services-items">
            <div className={"c-services-item"}>
              <h3>{service1["title"]}</h3>
              <p>{service1["description"]}</p>
            </div>
            <div className={"c-services-item"}>
              <h3>{service2["title"]}</h3>
              <p>{service2["description"]}</p>
            </div>
            <div className={"c-services-item"}>
              <h3>{service3["title"]}</h3>
              <p>{service3["description"]}</p>
            </div>
            <div className={"c-services-item"}>
              <h3>{service4["title"]}</h3>
              <p>{service4["description"]}</p>
            </div>
            <div className={"c-services-item"}>
              <h3>{service5["title"]}</h3>
              <p>{service5["description"]}</p>
            </div>
            <div className={"c-services-item"}>
              <h3>{service6["title"]}</h3>
              <p>{service6["description"]}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
